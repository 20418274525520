/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, getUrlImage, showToast } from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Service from "services/categories";
import ProductsService from "services/products";
import Detail from "./components/Detail";
import debounce from "lodash.debounce";
import { Dropdown } from "primereact/dropdown";
import { TreeTable } from "primereact/treetable";
import { Image } from "primereact/image";
import { useParams } from "react-router-dom";
import CategoryFilterService from "services/category-filters";
import { copyText } from "utils/common";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import NumberEditorCell from "components/v-edit-cell/NumberEditorCell";
import { Panel } from "primereact/panel";
import { CATEGORY_EXTRA_FORM_GROUP, COMMON_STATUS } from "utils/enum";
import { MasterContext } from "contexts/MasterContext";
import { CATEGORY_GROUP_KEY } from "utils/constant";

const Crud = () => {
  let params: any = useParams();

  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [onlyView, setOnlyView] = useState(false);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    group: params?.group,
    parent_id: null,
    status: null,
    attribute_id: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 99999,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
    group: params?.group,
  });

  const defaultOptions = {
    options: null,
    rowData: null,
    value: null,
  };
  // const [totalRecords, setTotalRecords] = useState(0);
  const [categories, setCategories] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const { attributeSets } = useContext(MasterContext);

  const [keySearch, setKeySearch] = useState("");
  const [optionSelect, setOptionSelect] = useState(defaultOptions);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDialogDisable = useRef(null);

  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  useEffect(() => {
    getCategories();
    getCategoryFilters();
  }, []);

  useEffect(() => {
    if (params) {
      setLazyParams((prev) => ({
        ...prev,
        group: params?.group,
      }));
      getCategories();
    }
  }, [params]);

  const getCategories = async () => {
    const res: any = await Service.getByGroup({
      query: { group: params?.group },
    });
    setCategories(res);
  };

  const getCategoryFilters = async () => {
    try {
      const res: any = await CategoryFilterService.getAll();
      if (res) {
        setCategoryFilters(res);
      }
    } catch (error) {}
  };

  // const loadCategoryGroup = () => {
  //   ServiceGroups.getAll().then((res: any) => {
  //     if (res) {
  //       setGroups(res);
  //     }
  //   });
  // };

  useEffect(() => {
    if (data) {
      const mapAll = dataList.map((e: any) => ({
        ...e,
        key: e._id != null ? String(e._id) : null,
        keyVal: e.key,
        parent_id: e.parent_id ? e.parent_id : null,
      }));

      const mapList = data.map((e: any) => ({
        ...e,
        key: e._id != null ? String(e._id) : null,
        keyVal: e.key,
        parent_id: e.parent_id ? e.parent_id : null,
      }));
      const tree = mapList.filter((e: any) => e.parent_id == null);

      tree.forEach((el: any) => {
        const items = getItems(mapAll, el._id);
        if (items && items != null && items.length > 0) {
          el.children = items;
          el.leaf = false;
        }
      });

      if (tree.length === 0 && mapList) {
        const treeChild = mapList.map((el: any) => {
          const items = getItems(mapAll, el._id);
          if (items && items != null && items.length > 0) {
            el.children = items;
            el.leaf = false;
          }
          return el;
        });
        setTreeData([...treeChild]);
      } else {
        setTreeData([...tree]);
      }
    } else setTreeData([]);
  }, [data, dataList]);

  const loadData = async () => {
    try {
      const res: any = await Service.search({
        query: {
          ...globalFilter,
          ...lazyParams,
          page: lazyParams.page + 1,
          limit: lazyParams.rows,
        },
      });
      if (res && res.docs) {
        setDataList(res.docs);
        setData(res.docs);
      } else {
        setData([]);
        setDataList([]);
      }
    } catch (error) {
      setData([]);
    }
  };

  const getItems = (mapList, parentId) => {
    let items = mapList.filter(
      (e: any) => String(e.parent_id) === String(parentId)
    );
    items.forEach((el: any) => {
      const items = getItems(mapList, el._id);
      if (items && items != null && items.length > 0) {
        el.items = items;
        el.children = items;
        el.leaf = false;
      }
    });
    return items;
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setOnlyView(false);
    setDetail(null);
    refDialogDetail.current.show();
  };

  const editMenuCategory = (data) => {
    setOnlyView(false);
    setDetail({ ...data, key: data.keyVal });
    refDialogDetail.current.show();
  };

  const viewCategory = (data) => {
    setDetail({ ...data, key: data.keyVal });
    setOnlyView(true);
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    setOnlyView(false);
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await Service.deleted({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Category Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeStatus = async (options, rowData, value) => {
    if (options?.field === "status" && value === "disable") {
      setOptionSelect({ options, rowData, value });
      refDialogDisable.current.show();
      return;
    }

    let updateRow = dataList?.find((e) => e?._id === rowData?._id);
    if (updateRow) {
      updateRow.status = value;
      await Service.update({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }

    loadData();
    showToast(toast, "success", options?.field + " updated!");
  };

  const handleDisable = async () => {
    const { options, rowData, value } = optionSelect;
    let updateRow = dataList?.find((e) => e?._id === rowData?._id);
    if (updateRow) {
      updateRow.status = value;
      await Service.update({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }

    refDialogDisable.current.close();
    setOptionSelect(defaultOptions);
    loadData();
    showToast(toast, "success", options?.field + " updated!");
  };

  const onFiltergroup = async (e) => {
    if (e?.filter) {
      const res: any = await Service.search({
        query: {
          group: params?.group,
          search: e?.filter,
          page: 1,
          limit: 10,
        },
      });
      if (res && res?.docs) {
        const newData = res?.docs?.filter(
          (el) => !categories?.find((item) => item?._id === el?._id)
        );
        setCategories((categories || []).concat(newData));
      }
    }
  };

  const onChangeOrder = debounce(onChangeStatus, 700);
  const onSearchFilterGroup = debounce(onFiltergroup, 700);

  // const orderEditor = (options) => {
  //   return <NumberEditor options={options} onChange={onChangeOrder} />;
  // };

  // const statusEditor = (options) => {
  //   return <StatusEditor options={options} onChange={onChangeStatus} />;
  // };

  const clearCache = async () => {
    try {
      await ProductsService.clearCache();
      showToast(toast, "success", "Cache Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />

          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {CATEGORY_EXTRA_FORM_GROUP.PRODUCT_CATEGORY_NORMAL ===
          params?.group && (
          <Button
            label="Clear Cache"
            icon="pi pi-trash"
            className="p-button-help mr-2"
            onClick={clearCache}
          />
        )}

        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const addChild = (data) => {
    let childData = { parent_id: data._id, group: params?.group };
    setDetail({ ...childData });
    refDialogDetail.current.show();
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {/* {!rowData.leaf && ( */}
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => addChild(rowData)}
        />
        {/* // )} */}
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editMenuCategory(rowData)}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning mr-2"
          onClick={() => confirmDelete(rowData)}
        />

        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-sm p-button-success"
          onClick={() => viewCategory(rowData)}
        />
      </div>
    );
  };

  const getByParent = (parent) => {
    if (categories) {
      let mapRes = categories.filter((e) => e.parent_id === parent);
      mapRes.forEach((e) => {
        if (categories.find((el) => el.parent_id === e._id)) {
          e.leaf = false;
        } else {
          e.leaf = true;
        }
      });
      return mapRes;
    } else {
      return [];
    }
  };

  const onExpand = (event) => {
    let lazyNode = { ...event.node };

    lazyNode.children = getByParent(event.node._id);
    let _nodes = data.map((e) => {
      if (e.key === event.node.key) {
        e = lazyNode;
      } else {
        e.children =
          e.children.length > 0 ? buildNode(e.children, lazyNode) : null;
      }
      return e;
    });

    setData(_nodes);
  };

  const buildNode = (children, lazyNode) => {
    let _nodes = children.map((chil) => {
      if (chil.key === lazyNode.key) {
        chil = lazyNode;
        return chil;
      }
      if (chil.children && chil.children.length > 0) {
        chil.children = buildNode(chil.children, lazyNode);
      }
      return chil;
    });

    return _nodes;
  };

  const onSearchByKey = (e: any) => {
    if (e.key === "Enter") {
      handleFilterTree();
    }
  };

  const handleFilterTree = () => {
    if (keySearch?.length > 0) {
      const filterDataList = dataList?.filter(
        (e) =>
          e?.name?.toUpperCase()?.includes(keySearch?.toUpperCase()) ||
          e?._id?.includes(keySearch)
      );
      setData(filterDataList);
    } else {
      setData(dataList);
    }
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid my-2 align-items-center">
        <div className="col-12 md:col-6">
          <span className="block m-1 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={keySearch}
              onInput={(e: any) => setKeySearch(e.target.value)}
              onKeyDown={onSearchByKey}
              placeholder="Search..."
              className="w-full"
            />
          </span>
        </div>
        <div className="col-12 md:col-6">
          <Dropdown
            value={globalFilter.parent_id}
            options={categories}
            optionLabel="name"
            optionValue="_id"
            onChange={(e) => onSearch("parent_id", e.value)}
            showClear
            filter
            onFilter={(e) => onSearchFilterGroup(e)}
            placeholder="Parent"
            className="w-full"
          />
        </div>
        <div className="col-12 md:col-6">
          <Dropdown
            value={globalFilter.status}
            options={Object.keys(COMMON_STATUS).map((k) => ({
              label: k,
              value: COMMON_STATUS[k],
            }))}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="label"
            placeholder="Status"
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-6">
          <Dropdown
            value={globalFilter.attribute_id}
            options={attributeSets?.map((k) => ({
              label: k.title,
              value: k._id,
            }))}
            optionLabel="label"
            optionValue="value"
            placeholder="Attribute"
            filter
            onChange={(e) => onSearch("attribute_id", e.value)}
            className="w-full"
            showClear
          />
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <TreeTable
            ref={dt}
            value={treeData}
            header={header}
            onExpand={onExpand}
            // paginator
            rowsPerPageOptions={[5, 10, 25]}
            emptyMessage="No data found."
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            onSort={onSort}
          >
            <Column
              // frozen
              // alignFrozen="left"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "260px" }}
            ></Column>
            {/* <Column
              field="key"
              header="Key"
              style={{ flexGrow: 1, flexBasis: "150px" }}

              body={(rowData) => <span>{rowData.key}</span>}
            ></Column> */}
            <Column
              field="name"
              header="Name"
              expander
              style={{ flexGrow: 1, flexBasis: "180px" }}
              body={(rowData) => <span>{rowData.name}</span>}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "30px" }}
              header="Icon"
              body={(rowData) => {
                return (
                  <Image
                    src={getUrlImage(rowData.icon)}
                    alt={rowData.name}
                    width="50"
                    height="50"
                    preview
                  />
                );
              }}
            ></Column>
            <Column
              // editor={orderEditor}
              field="order"
              header="Order"
              style={{ flexGrow: 1, width: "8%" }}
              body={(rowData, options) => (
                <NumberEditorCell
                  value={rowData.order}
                  options={options}
                  onChange={onChangeOrder}
                />
              )}
              sortable
            ></Column>
            <Column
              // editor={statusEditor}
              field="status"
              header="Status"
              style={{ flexGrow: 1, flexBasis: "100px" }}
              // body={(rowData) => (
              //   <span
              //     className={`v-badge status-${rowData.status?.toLowerCase()}`}
              //   >
              //     {rowData.status}
              //   </span>
              // )}
              body={(rowData, options) => (
                <StatusEditorCell
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                />
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="updatedAt"
              header="Updated At"
              sortable
              body={(rowData) => formatLocalTime(rowData.updatedAt)}
            ></Column>

            {params?.group === CATEGORY_GROUP_KEY.BANNER_PAGE && (
              <Column
                field="banner"
                header="Link banner"
                style={{ flexGrow: 1, width: "8%" }}
                body={(rowData) => (
                  <Button
                    onClick={(_) => {
                      copyText(
                        `${process.env.REACT_APP_CLIENT_DOMAIN}/banners?menuKey=&positionBanner=${rowData.key}&userId=&notLoadDefault=true`
                      );
                      showToast(toast, "success", "Copied to clipboard!");
                    }}
                    icon="bx bx-copy"
                  ></Button>
                )}
              ></Column>
            )}
          </TreeTable>

          <VDialog
            ref={refDialogDetail}
            header="Detail"
            onSubmit={onSumitDialogDetail}
            onlyView={onlyView}
          >
            <Detail
              ref={refDetail}
              data={detail}
              group={params?.group}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
              categoryFilters={categoryFilters}
              getCategoryFilters={getCategoryFilters}
              onlyView={onlyView}
            />
          </VDialog>

          <VConfirm
            ref={refDialogDisable}
            isDisable={true}
            onConfirm={handleDisable}
          />

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
