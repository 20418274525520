import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:3001/api",
  headers: {
    "x-custom-origin": "",
  },
});

export const request = (options: any = {}) => {
  const AUTH_TOKEN = localStorage.getItem("token_auth");
  axiosInstance.defaults.headers["Content-Type"] = "application/json";
  axiosInstance.defaults.headers.common["Authorization"] =
    "Beare " + AUTH_TOKEN;
  if (options.headers) {
    axiosInstance.defaults.headers = {
      ...axiosInstance.defaults.headers,
      ...options.headers,
    };
  }

  return axiosInstance;
};

export const parseErrorResponse = (err: any) => {
  if (err && err.response) {
    if (err.response.data.code === 401) {
      //   cookies.remove('authorization');
    }
    return err.response.data;
  }
  return new Error("Bad request");
};
