export enum EFIELD {
  LINK = "link",
  LINK_VIDEO = "link_video",
  INPUT = "input",
  TEXTAREA = "textarea",
  DROPDOWN = "dropdown",
  MULTI_CHOISE = "multi_choise",
  CHECKBOXES = "checkboxes",
  IMAGE = "image",
  VIDEO = "video",
  ADDRESS = "address",
  TEXT_EDITOR = "text_editor",
  DATE = "date",
  DATE_TIME = "date_time",
  RADIO = "radio",
  CAPTCHA_IMAGE = "captcha_image",
  MEDIA = "media",
  RELIGION = "religion",
  JOB = "job",
  RELATIONSHIP = "relationship",
  OBJECT_TYPE = "object_type",
  ACCOUNT_TYPE = "account_type",
  GENDER = "gender",
  FAMILIES = "families",
  TOPIC = "topic",
  NATION = "nation",
}

export const FIELDS: { name: string; value: EFIELD }[] = [
  {
    name: "Link",
    value: EFIELD.LINK,
  },
  {
    name: "Link video",
    value: EFIELD.LINK_VIDEO,
  },
  {
    name: "Input",
    value: EFIELD.INPUT,
  },
  {
    name: "Textarea",
    value: EFIELD.TEXTAREA,
  },
  {
    name: "Dropdown",
    value: EFIELD.DROPDOWN,
  },
  {
    name: "Multi choice",
    value: EFIELD.MULTI_CHOISE,
  },
  {
    name: "Checkboxes",
    value: EFIELD.CHECKBOXES,
  },
  {
    name: "Radio",
    value: EFIELD.RADIO,
  },
  {
    name: "Image",
    value: EFIELD.IMAGE,
  },
  {
    name: "Video",
    value: EFIELD.VIDEO,
  },
  {
    name: "Address",
    value: EFIELD.ADDRESS,
  },
  {
    name: "Text Editor",
    value: EFIELD.TEXT_EDITOR,
  },
  {
    name: "Date",
    value: EFIELD.DATE,
  },
  {
    name: "Date Time",
    value: EFIELD.DATE_TIME,
  },
  {
    name: "Captcha image",
    value: EFIELD.CAPTCHA_IMAGE,
  },
  {
    name: "Media/file",
    value: EFIELD.MEDIA,
  },
  {
    name: "Tôn giáo",
    value: EFIELD.RELIGION,
  },
  {
    name: "Ngành nghề",
    value: EFIELD.JOB,
  },
  {
    name: "Mỗi quan hệ",
    value: EFIELD.RELATIONSHIP,
  },
  {
    name: "Đối tượng",
    value: EFIELD.OBJECT_TYPE,
  },
  {
    name: "Loại tài khoản",
    value: EFIELD.ACCOUNT_TYPE,
  },
  {
    name: "Giới tính",
    value: EFIELD.GENDER,
  },
  {
    name: "Gia tộc",
    value: EFIELD.FAMILIES,
  },
  {
    name: "Chủ đề quan tâm",
    value: EFIELD.TOPIC,
  },
  {
    name: "Dân tộc",
    value: EFIELD.NATION,
  },
];

export const DATA_TYPES = [
  {
    name: "Number",
    value: "number",
  },
  {
    name: "Datetime",
    value: "datetime",
  },
  {
    name: "Phone number (ex: +84 123456)",
    value: "phonenumber_with_region",
  },
  {
    name: "Phone number (ex: 0123456)",
    value: "phonenumber",
  },
];
