/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import UserService from "services/users";
import { formatLocalTime, showToast } from "utils/common";
import Details from "./components/Details";
import CreateMulti from "./components/Multi";
import Roles from "./components/Roles";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { OBJECT_TYPE, USER_STATUS, REGISTER_TYPE, ROLE } from "utils/enum";
import { Dropdown } from "primereact/dropdown";
import VLocation from "components/v-location";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import { renderAddress } from "utils/render";
import { MasterContext } from "contexts/MasterContext";
import { Panel } from "primereact/panel";
import { MultiSelect } from "primereact/multiselect";
import { getStringLimit } from "utils/common";
import GroupCategoryService from "services/categories-groups";
import CategoryService from "services/categories";

const STATUS_KYC = [
  { name: "Kyc", value: "kyc" },
  { name: "Verified", value: "verified" },
];

const View = () => {
  const { auth } = useContext(MasterContext);
  // const _date = getStartAndEndDate(7);
  const [accountTypes, setAccountTypes] = useState([]);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [groups, setGroups] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: USER_STATUS.CONFIRMED,
    object_type: null,
    account_type: null,
    register_type: null,
    status_kyc: null,
    role: null,
    category_group: null,
    is_create_multi: null,
    referred_by: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [locationFilter, setLocationFilter] = useState({
    country: null,
    province: null,
    district: null,
    ward: null,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadingExport, setLoadingExport] = useState(false);
  const [isMulti, setIsMulti] = useState(false);
  const { locations } = useContext(MasterContext);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDialogRole = useRef(null);

  const refDetail = useRef(null);
  const refRole = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter, locationFilter]);

  useEffect(() => {
    loadCategoryGroup();
    getAccountTypes();
  }, []);

  const getAccountTypes = async () => {
    const res: any = await CategoryService.getByGroup({
      query: {
        group: "account_type",
      },
    });
    setAccountTypes(res);
  };

  const loadData = async () => {
    try {
      const res: any = await UserService.search({
        query: {
          ...globalFilter,
          ...locationFilter,
          ...lazyParams,
          limit: lazyParams.rows,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  const loadCategoryGroup = () => {
    GroupCategoryService.getAll().then((res: any) => {
      if (res) {
        setGroups(res);
      }
    });
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onFilter = (name, value) => {
    if (name === "location") {
      setLocationFilter(value);
    } else {
      setGlobalFilter((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = (isMulti) => {
    setIsMulti(isMulti);
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = async (data) => {
    setIsMulti(false);
    const res: any = await UserService.getUserById({
      params: {
        id: data._id,
      },
    });
    if (res) {
      setDetail({
        ...data,
        ...res.user,
      });
      refDialogDetail.current.show();
    }
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  //  ROLES
  const onSubmitDialogRole = () => {
    refRole.current.submit();
  };
  const editRole = (data) => {
    setDetail({ ...data });
    refDialogRole.current.show();
  };
  const setLoadingSaveRole = (flg) => {
    refDialogRole.current.setLoading(flg);
  };
  const onCancelDialogRole = () => {
    refDialogRole.current.close();
  };

  // DELETE
  // const confirmDelete = (product) => {
  //   setDetail(product);
  //   refDialogDelete.current.show();
  // };
  const handleDelete = async () => {
    try {
      await UserService.deleteUser({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "User Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeStatus = async (options, rowData, value) => {
    let updateRow;
    let newData = data.map((e, index) => {
      if (e._id === rowData._id) {
        e[options.field] = value;
        updateRow = e;
      }
      return e;
    });
    setData(newData);

    if (updateRow) {
      await UserService.updateUser({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }
  };

  // const statusEditor = (options) => {
  //   return (
  //     <StatusEditor
  //       options={options}
  //       status={USER_STATUS}
  //       onChange={onChangeStatus}
  //     />
  //   );
  // };

  // IMPORT / EXPORT
  const exportExcel = async () => {
    try {
      setLoadingExport(true);
      const res: any = await UserService.getDataExport({
        query: {
          ...lazyParams,
          ...globalFilter,
          ...locationFilter,
          page: 1,
        },
      });
      import("xlsx").then((xlsx) => {
        const _data = res.docs.map((item, index) => ({
          STT: index + 1,
          ID: item.refer_code,
          FullName: item.full_name,
          Object: item.object_type,
          Email: item.email,
          Phone: item.phone,
          BirthDay: new Date(item.profile?.birthday).toLocaleDateString(),
          Address: renderAddress(item.profile.location, locations),
        }));
        const worksheet = xlsx.utils.json_to_sheet(_data);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "users");
        setLoadingExport(false);
      });
    } catch (error) {
      setLoadingExport(false);
    }
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={(_) => openNew(false)}
          />
          <Button
            label="Create Multi User"
            icon="pi pi-plus"
            className="p-button-warning mr-2"
            onClick={(_) => openNew(true)}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-file-excel"
          className="p-button-help"
          loading={loadingExport}
          onClick={exportExcel}
        />
      </React.Fragment>
    );
  };

  const editAccountPossitionIndex = (rowData: any, value: any) => {
    UserService.updateUser({
      params: { id: rowData._id },
      body: {
        account_type_index: isNaN(+value) ? 0 : parseInt(value),
      },
    })
      .then((res) => {
        console.log(res);
        showToast(toast, "success", "User saved!");
      })
      .catch((err) => {
        console.log(err);
        showToast(toast, "error", "User saved failed!");
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions flex flex-row align-items-center justify-content-center">
        {rowData.role !== ROLE.ADMIN && auth.role === ROLE.ADMIN && (
          <Button
            icon="bx bxs-cog text-2xl"
            className="p-button-rounded p-button-sm p-button-info mr-2"
            onClick={() => editRole(rowData)}
          />
        )}
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <InputText
          type="number"
          placeholder="0"
          style={{
            width: "50px",
          }}
          defaultValue={rowData.account_type_index}
          onBlur={(e) => {
            editAccountPossitionIndex(rowData, e.target.value);
          }}
        />
        {/* <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        /> */}
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2">
        <div className="col-12 md:col-3">
          <div className="block px-0 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </div>
        </div>
        <div className="col-12 md:col-3">
          <Dropdown
            value={globalFilter.status}
            options={Object.keys(USER_STATUS).map((k) => ({
              label: k,
              value: USER_STATUS[k],
            }))}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="label"
            placeholder="Status"
            className={"w-full "}
            showClear
          />
        </div>
        <div className="col-12 md:col-3">
          <Dropdown
            value={globalFilter.status_kyc}
            options={STATUS_KYC.map((k) => ({
              label: k.name,
              value: k.value,
            }))}
            onChange={(e: any) => onSearch("status_kyc", e.value)}
            optionLabel="label"
            placeholder="Status KYC"
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-3">
          <Dropdown
            value={globalFilter.role}
            options={Object.keys(ROLE).map((k) => ({
              label: k,
              value: ROLE[k],
            }))}
            onChange={(e: any) => onSearch("role", e.value)}
            optionLabel="label"
            placeholder="Role"
            className={"w-full "}
            showClear
          />
        </div>

        <div className="col-12 md:col-6">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />{" "}
        </div>
        <div className="col-12 md:col-6">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
        <div className=" col-12 md:col-3">
          <Dropdown
            value={globalFilter.object_type}
            options={Object.keys(OBJECT_TYPE).map((k) => ({
              label: k,
              value: OBJECT_TYPE[k],
            }))}
            onChange={(e: any) => onSearch("object_type", e.value)}
            optionLabel="label"
            placeholder="Object"
            className="w-full"
            showClear
          />
        </div>
        <div className=" col-12 md:col-3">
          <MultiSelect
            value={globalFilter.account_type}
            options={accountTypes.map((c) => ({
              label: c.name,
              value: c.key,
            }))}
            onChange={(e: any) => onSearch("account_type", e.value)}
            optionLabel="label"
            placeholder="Account type"
            className="w-full"
            showClear
          />
        </div>
        <div className="col-12 md:col-3">
          <Dropdown
            value={globalFilter.category_group}
            options={groups}
            optionLabel="name"
            optionValue="key"
            onChange={(e) => onSearch("category_group", e.value)}
            showClear
            filter
            placeholder="Category Group"
            className="w-full"
          />
        </div>
        <div className=" col-12 md:col-3">
          <Dropdown
            value={globalFilter.register_type}
            options={[
              Object.keys(REGISTER_TYPE).map((k) => ({
                label: k,
                value: REGISTER_TYPE[k],
              })),
            ]}
            onChange={(e: any) => onSearch("register_type", e.value)}
            optionLabel="label"
            placeholder="Register type"
            className="w-full"
            showClear
          />
        </div>
        <div className=" col-12 md:col-6">
          <Dropdown
            value={globalFilter.is_create_multi}
            options={[
              {
                label: "TRUE",
                value: true,
              },
              {
                label: "FALSE",
                value: false,
              },
            ]}
            onChange={(e: any) => onSearch("is_create_multi", e.value)}
            optionLabel="label"
            placeholder="Is Multi"
            className="w-full"
            showClear
          />
        </div>
        <div className="col-12 md:col-6">
          <div className="block px-0 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("referred_by", e.target.value)}
              placeholder="Referred by..."
              className="w-full"
            />
          </div>
        </div>

        <div className="col-12">
          <VLocation
            address={locationFilter}
            setAddress={(a) => onSearch("location", a)}
            viewInLine={false}
          />
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              alignFrozen="left"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "170px" }}
            ></Column>
            <Column
              field="sites"
              header="Sites"
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => getStringLimit(String(`${rowData.sites}`), 35)}
            ></Column>
            <Column
              field="refer_code"
              header="Id"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => String(rowData.refer_code || "").slice(0, 20)}
            ></Column>
            <Column
              field="email"
              header="Email"
              style={{ flexGrow: 1, flexBasis: "350px" }}
              body={(rowData) => getStringLimit(rowData.email, 35)}
            ></Column>
            {/* <Column
              style={{ flexGrow: 1, flexBasis: "300px" }}
              field="phone"
              header="Phone"
            ></Column> */}
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="referred_by"
              header="Sponsor"
              body={(rowData) => String(rowData.referred_by || "").slice(0, 20)}
            ></Column>
            <Column
              // editor={statusEditor}
              style={{ flexGrow: 1, flexBasis: "180px" }}
              field="status"
              header="Status"
              sortable
              // body={(rowData) => (
              //   <span
              //     className={`v-badge status-${rowData.status.toLowerCase()}`}
              //   >
              //     {rowData.status}
              //   </span>
              // )}
              body={(rowData, options) => (
                <StatusEditorCell
                  status={USER_STATUS}
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                />
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status_kyc"
              header="Status KYC"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Details"
            onSubmit={onSumitDialogDetail}
          >
            {isMulti ? (
              <CreateMulti
                ref={refDetail}
                data={detail}
                toast={toast}
                reload={loadData}
                setLoading={setLoadingSaveDetail}
                onCancel={onCancelDialogDetail}
              />
            ) : (
              <Details
                ref={refDetail}
                data={detail}
                toast={toast}
                reload={loadData}
                setLoading={setLoadingSaveDetail}
                onCancel={onCancelDialogDetail}
              />
            )}
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />

          <VDialog
            ref={refDialogRole}
            header="User Role"
            onSubmit={onSubmitDialogRole}
          >
            <Roles
              ref={refRole}
              toast={toast}
              user={detail}
              setLoading={setLoadingSaveRole}
              onCancel={onCancelDialogRole}
            />
          </VDialog>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
