/* eslint-disable react-hooks/exhaustive-deps */
import CategoryFilterDetails from "components/v-filter";
import VUpload from "components/v-upload";
import { MasterContext } from "contexts/MasterContext";
import debounce from "lodash.debounce";
import { nanoid } from "nanoid";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { TreeSelect } from "primereact/treeselect";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Service from "services/categories";
import { getUrlImage, showToast } from "utils/common";
import { CATEGORY_EXTRA_FORM_GROUP, CATEGORY_STATUS } from "utils/enum";

import CategoryService from "services/categories";
import { CATEGORY_GROUP_KEY } from "utils/constant";
import { ColorPicker } from "primereact/colorpicker";
import { TabPanel, TabView } from "primereact/tabview";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    group,
    categoryFilters,
    getCategoryFilters,
    onlyView,
  } = props;

  const refDetailCategory = useRef(null);
  const refDetailPermissionCategory = useRef(null);
  const emptyAdditionalData = {
    icon_maps: null,
    is_show_map: true,
    is_show_create_post: false,
    is_show_affiliate: true,
    icon_donate: null,
    product_type: null,
    attributes: [],
    fields: {},
    width: null,
    height: null,
    count: null,
    is_my_shop: false,
    is_free: false,
    product_view_type: null,
  };
  const emptyData = {
    view: "",
    name: "",
    key: "",
    group: group,
    description: "",
    parent_id: data?.parent_id,
    icon: "",
    status: CATEGORY_STATUS.ENABLE,
    category_filter_id: null,
    permission_category_filter_id: null,
    images: [],
    video: null,
    order: null,
    position: null,
    attribute_id: null,
    permission_user: [],
    thumbnail: "",
    thumbnail_loading_time: null,
    background_color: "",
    url_active: null,
    price_ads: 0,
    sites: null,
    default_info: null,
  };

  const [filterParents, setFilterParents] = useState([]);
  const [details, setDetails] = useState(emptyData);
  const [additionalData, setAdditionalData] = useState(emptyAdditionalData);
  const { attributeSets, sites } = useContext(MasterContext);
  const [attributesChild, setAttributesChild] = useState([]);
  const [attributesSelected, setAttributeSelected] = useState([]);
  const [notChangedAttribute, setNotChangedAttribute] = useState(true);
  const [selectedNodeFields, setSelectedNodeFields] = useState(null);
  const [types, setTypes] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const [parents, setParents] = useState<any>();
  const [parentCurrentOptions, setParentCurrentOptions] = useState<any>();
  const [lazyParams, setLazyParams]: any = useState({
    search: "",
    limit: 20,
    page: 1,
    group: group,
  });

  const [treeFields] = useState([
    {
      label: "Đầy đủ",
      key: "ALL",
      children: [
        {
          label: "Thông tin chung",
          key: "Thông tin chung",
          children: [
            { label: "Mô tả niêm yết", key: "Mô tả niêm yết" },
            { label: "Ưu tiên", key: "priority" },
            { label: "Driver", key: "driver" },
          ],
        },
        {
          label: "Cài đặt niêm yết",
          key: "Cài đặt niêm yết",
          children: [
            { label: "Phương thức bán hàng", key: "Phương thức bán hàng" },
            { label: "Liên hệ", key: "Liên hệ" },
            { label: "Giá gốc", key: "Giá gốc" },
            { label: "Giá bán", key: "Giá bán" },
            { label: "Cổ phần", key: "Cổ phần" },
            { label: "Thuế", key: "Thuế" },
            { label: "Thanh toán bằng stock", key: "Thanh toán bằng stock" },
            { label: "Tổng số niêm yết", key: "Tổng số niêm yết" },
            { label: "Giới hạn", key: "Giới hạn" },
            { label: "Cài đặt nhà phân phối", key: "Cài đặt nhà phân phối" },
            { label: "Cài đặt giá sỉ", key: "Cài đặt giá sỉ" },
            { label: "Countdown", key: "Countdown" },
            { label: "Quản lý chi nhánh", key: "Quản lý chi nhánh" },
          ],
        },
        { label: "Tệp đính kèm", key: "Tệp đính kèm" },
        { label: "Thuộc tính", key: "Thuộc tính" },
        { label: "Niêm yết mua cùng", key: "Niêm yết mua cùng" },
        { label: "Affiliates", key: "Affiliates" },
        { label: "Topic", key: "Topic" },
      ],
    },
  ]);

  const defaultSelectedAllFields = {
    ALL: {
      checked: true,
      partialChecked: false,
    },
    "Thông tin chung": {
      checked: true,
      partialChecked: false,
    },
    "Mô tả niêm yết": {
      checked: true,
      partialChecked: false,
    },
    "Cài đặt niêm yết": {
      checked: true,
      partialChecked: false,
    },
    "Phương thức bán hàng": {
      checked: true,
      partialChecked: false,
    },
    "Liên hệ": {
      checked: true,
      partialChecked: false,
    },
    "Giá gốc": {
      checked: true,
      partialChecked: false,
    },
    "Giá bán": {
      checked: true,
      partialChecked: false,
    },
    "Cổ phần": {
      checked: true,
      partialChecked: false,
    },
    Thuế: {
      checked: true,
      partialChecked: false,
    },
    "Thanh toán bằng stock": {
      checked: true,
      partialChecked: false,
    },
    "Tổng số niêm yết": {
      checked: true,
      partialChecked: false,
    },
    "Giới hạn": {
      checked: true,
      partialChecked: false,
    },
    "Cài đặt nhà phân phối": {
      checked: true,
      partialChecked: false,
    },
    "Cài đặt giá sỉ": {
      checked: true,
      partialChecked: false,
    },
    Countdown: {
      checked: true,
      partialChecked: false,
    },
    "Quản lý chi nhánh": {
      checked: true,
      partialChecked: false,
    },
    "Tệp đính kèm": {
      checked: true,
      partialChecked: false,
    },
    "Thuộc tính": {
      checked: true,
      partialChecked: false,
    },
    "Niêm yết mua cùng": {
      checked: true,
      partialChecked: false,
    },
    Affiliates: {
      checked: true,
      partialChecked: false,
    },
  };

  const haveFilterGroups = [
    CATEGORY_EXTRA_FORM_GROUP.PRODUCT_CATEGORY_NORMAL,
    CATEGORY_EXTRA_FORM_GROUP.AGE,
    CATEGORY_EXTRA_FORM_GROUP.PROFILE_DIARYBOOK_NOT_DELETE,
  ];
  const haveFilter = true;

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
    if (data && data.attribute_id)
      setAttributesChild(
        attributeSets?.find((e) => e?._id === data?.attribute_id)?.attributes
      );
    if (data && data.additionalDataForm) {
      setAdditionalData({
        ...emptyAdditionalData,
        ...data.additionalDataForm,
      });
      setAttributeSelected(data.additionalDataForm?.attributes);
      setSelectedNodeFields(
        data.additionalDataForm?.fields || defaultSelectedAllFields
      );
    }

    if (data?.parent_id?.length > 0) {
      getParentInfo(data.parent_id);
    }
  }, [data]);

  useEffect(() => {
    if (details) {
      setAdditionalData((prev) => ({
        ...prev,
        attributes: attributesChild?.map((e) => e?.id),
      }));
      getCategoryProductTypes();
    }
  }, [details]);

  const onChange = (name, value) => {
    if (name === "attribute_id") {
      setAttributesChild(
        attributeSets?.find((e) => e?._id === value)?.attributes
      );
      setNotChangedAttribute(false);
    }
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const getCategoryProductTypes = async () => {
    try {
      const categories: any = await CategoryService.getByGroup({
        query: {
          group: "product_category_type",
        },
      });
      setTypes(categories);
    } catch (error) {}
  };

  const onChangeAddData = (name, value) => {
    if (name === "attributes") {
      setAttributeSelected(value);
      setNotChangedAttribute(false);
    }
    let _addData = { ...additionalData };
    _addData[`${name}`] = value;
    setAdditionalData(_addData);
  };

  const onChangePermission = (index, value) => {
    let permission = details.permission_user?.map((el, idx) =>
      idx === index ? value : el
    );
    setDetails({ ...details, permission_user: permission });
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      let permission_posting = null;
      if (!details.category_filter_id && haveFilter) {
        if (refDetailCategory && refDetailCategory?.current) {
          category = await refDetailCategory.current.submit();
        }
        getCategoryFilters();
      }

      if (!details.permission_category_filter_id) {
        if (
          refDetailPermissionCategory &&
          refDetailPermissionCategory?.current
        ) {
          permission_posting =
            await refDetailPermissionCategory.current.submit();
        }
        getCategoryFilters();
      }

      if (group === CATEGORY_GROUP_KEY.BANNER_PAGE && !additionalData?.width) {
        showToast(toast, "warn", "Width must not empty or zero!");
        setLoading(false);
        return;
      }

      if (data && data._id) {
        await Service.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            additionalDataForm: additionalData,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
            permission_category_filter_id: permission_posting
              ? permission_posting._id
              : details.permission_category_filter_id,
          },
        });
      } else
        await Service.create({
          body: {
            ...details,
            additionalDataForm: additionalData,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
            permission_category_filter_id: permission_posting
              ? permission_posting._id
              : details.permission_category_filter_id,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const onInputCheckChange = (val, name) => {
    setDetails({
      ...details,
      [name]: val,
    });
  };

  const moveUp = (index: number) => {
    const _images = details.images;
    const _before = details.images[index - 1];
    const _after = details.images[index];
    _images[index] = _before;
    _images[index - 1] = _after;
    setDetails((state) => ({ ...state, images: [..._images] }));
  };

  const moveDown = (index: number) => {
    const _images = details.images;
    const _before = details.images[index + 1];
    const _after = details.images[index];
    _images[index] = _before;
    _images[index + 1] = _after;
    setDetails((state) => ({ ...state, images: [..._images] }));
  };

  const handleSubIndex = (index) => {
    setDetails((state) => ({
      ...state,
      images: state.images.filter((i) => i.id !== index),
    }));
  };

  const changeLinkItemImages = (e: any, index: string) => {
    setDetails((state) => ({
      ...state,
      images: state.images.map((item) =>
        item.id === index ? { ...item, link: e.target.value } : item
      ),
    }));
  };

  const handleImageChange = (e: any, index: string) => {
    setDetails((state) => ({
      ...state,
      images: state.images.map((item) =>
        item.id === index ? { ...item, src: e } : item
      ),
    }));
  };

  const handleChangeDefaultInfo = (name, value) => {
    setDetails((state) => ({
      ...state,
      default_info: { ...state.default_info, [name]: value },
    }));
  };

  const handleAdd = () => {
    const added = [...details.images, { id: nanoid(), src: "", link: "" }];
    setDetails((state) => ({ ...state, images: added }));
  };

  const handleAddPermissionUser = () => {
    const permission_user = [...(details.permission_user || []), ""];
    setDetails((state) => ({ ...state, permission_user: permission_user }));
  };

  const handleRemovePermissionUser = (index) => {
    const permission_user = details.permission_user.filter(
      (el, idx) => idx !== index
    );
    setDetails((state) => ({ ...state, permission_user: permission_user }));
  };

  useEffect(() => {
    if (selectedNodeFields) {
      onChangeAddData("fields", selectedNodeFields);
      // Object.entries(selectedNodeFields)
      //   ?.filter((k: any) => k[1]?.checked)
      //   ?.map((k: any) => k[0])]
    }
  }, [selectedNodeFields]);

  useEffect(() => {
    if (attributesSelected) {
      setAdditionalData((state) => ({
        ...state,
        attributes: attributesSelected,
      }));
    }
  }, [attributesSelected]);

  const getParentInfo = async (id) => {
    const res: any = await Service.getById({
      params: { id },
    });
    setParentCurrentOptions(res);
  };

  useEffect(() => {
    getCategories();
  }, [lazyParams]);

  const getCategories = async () => {
    const res: any = await Service.filterByGroup({
      query: { group: group, ...lazyParams },
    });
    setParents(res);
    setFilterParents((pre) =>
      [
        ...(lazyParams?.isScroll ? [...pre]?.concat(res.docs) : res.docs),
      ]?.filter((e: { key: any }) => e?.key !== data?.key)
    );
  };

  const handleFilterParent = (event) => {
    setLazyParams((pre) => ({
      ...pre,
      search: event.filter,
      isScroll: false,
      page: 1,
    }));
  };

  const onFilterParent = useCallback(debounce(handleFilterParent, 500), []);

  const onScroll = (event) => {
    if (event.last % 15 === 0 && parents.hasNextPage) {
      setLazyParams((pre) => ({ ...pre, page: pre.page + 1, isScroll: true }));
    }
  };

  const categoryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option?.name}
          src={getUrlImage(option?.icon)}
          className={`mr-2 flag flag-${option.name?.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div>{option?.name}</div>
      </div>
    );
  };

  return (
    <TabView
      className="field col-12"
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
    >
      <TabPanel header="Setting">
        <div className="grid">
          <div className="field col-12 ">
            <label htmlFor="name">Sites</label>
            <MultiSelect
              filter
              value={details.sites}
              options={sites}
              optionLabel="name"
              optionValue="domain"
              onChange={(e) => onChange("sites", e.value)}
              disabled={onlyView}
            />
          </div>

          <div className="field col-6">
            <label htmlFor="name">Parent</label>
            <Dropdown
              value={details?.parent_id}
              options={filterParents}
              optionLabel="name"
              optionValue="_id"
              filter
              showFilterClear
              onFilter={onFilterParent}
              showClear
              itemTemplate={categoryOptionTemplate}
              virtualScrollerOptions={{
                lazy: true,
                onLazyLoad: onScroll,
                itemSize: 40,
              }}
              onChange={(e) => onChange("parent_id", e.value)}
              disabled={onlyView}
            />
          </div>
          <div className="field col-6">
            <label htmlFor="description">Status</label>
            <Dropdown
              value={details.status}
              options={Object.keys(CATEGORY_STATUS).map((k) => ({
                label: k,
                value: CATEGORY_STATUS[k],
              }))}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => onChange("status", e.value)}
              disabled={onlyView}
            />
          </div>
          <div className="field col-6">
            <label htmlFor="name">Key</label>
            <InputText
              id="key"
              value={details.key}
              onChange={(e) => onChange("key", e.target.value)}
              required
              autoFocus
              disabled={onlyView}
            />
          </div>
          <div className="field col-6">
            <label htmlFor="view">Chọn cách hiển thị</label>
            <Dropdown
              id="view"
              options={[
                {
                  name: "Normal (TMDT)",
                  value: "normal",
                },
                {
                  name: "Shop Feed",
                  value: "shop_feed",
                },
                {
                  name: "Liệt sĩ",
                  value: "martyr",
                },
                {
                  name: "Từ thiện",
                  value: "charity",
                },
              ]}
              value={details.view}
              onChange={(e) => onChange("view", e.value)}
              optionLabel="name"
              optionValue="value"
              showClear
              disabled={onlyView}
            />
          </div>
          <div className="field col-6">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              value={details.name}
              onChange={(e) => onChange("name", e.target.value)}
              required
              autoFocus
              disabled={onlyView}
            />
          </div>

          {group === CATEGORY_GROUP_KEY.FAKE_KEY_SEARCH && (
            <>
              <div className="field col-6">
                <label htmlFor="name">Count</label>
                <InputText
                  id="count"
                  value={additionalData.count}
                  onChange={(e) => onChangeAddData("count", e.target.value)}
                  required
                  autoFocus
                  disabled={onlyView}
                />
              </div>
            </>
          )}

          {group === CATEGORY_GROUP_KEY.BANNER_PAGE && (
            <>
              <div className="field col-6">
                <label htmlFor="name">Url active</label>
                <InputText
                  id="name"
                  value={details.url_active}
                  onChange={(e) => onChange("url_active", e.target.value)}
                  required
                  autoFocus
                  disabled={onlyView}
                />
              </div>
              <div className="field col-3">
                <label htmlFor="name">Width</label>
                <InputText
                  id="width"
                  value={additionalData.width}
                  onChange={(e) => onChangeAddData("width", e.target.value)}
                  required={true}
                  autoFocus
                  disabled={onlyView}
                />
              </div>
              <div className="field col-3">
                <label htmlFor="name">Height</label>
                <InputText
                  id="height"
                  value={additionalData.height}
                  onChange={(e) => onChangeAddData("height", e.target.value)}
                  autoFocus
                  disabled={onlyView}
                />
              </div>
            </>
          )}

          <div className="field col-6">
            <label htmlFor="name">Order</label>
            <InputNumber
              id="name"
              value={details.order}
              onChange={(e) => onChange("order", e.value)}
              disabled={onlyView}
            />
          </div>

          {[CATEGORY_EXTRA_FORM_GROUP.ACCOUNT_TYPE].includes(group) && (
            <div className="field col-12 ">
              <label htmlFor="attribute">Group Attribute</label>
              <Dropdown
                value={details.attribute_id}
                options={attributeSets?.map((k) => ({
                  label: k.title,
                  value: k._id,
                }))}
                showClear
                optionLabel="label"
                optionValue="value"
                filter
                onChange={(e) => onChange("attribute_id", e.value)}
                disabled={onlyView}
              />
            </div>
          )}

          <div className="field col-6">
            <label htmlFor="name">Price Ads</label>
            <InputNumber
              id="price_ads"
              value={details?.price_ads}
              onChange={(e) => onChange("price_ads", e.value)}
              disabled={onlyView}
            />
          </div>

          {group === CATEGORY_EXTRA_FORM_GROUP.PROFILE_DIARYBOOK_NOT_DELETE && (
            <div className="field col-12">
              <label htmlFor="attribute">Position</label>
              <Dropdown
                value={details.position}
                options={[
                  {
                    title: "Header",
                    value: 0,
                  },
                  {
                    title: "Footer",
                    value: 1,
                  },
                ]?.map((k) => ({
                  label: k.title,
                  value: k.value,
                }))}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => onChange("position", e.value)}
                disabled={onlyView}
              />
            </div>
          )}
          <div className="field col-6">
            <label>Thumbnail loading time (ms)</label>
            <div>
              <InputNumber
                id="name"
                value={details.thumbnail_loading_time}
                onChange={(e) => onChange("thumbnail_loading_time", e.value)}
                disabled={onlyView}
              />
            </div>
          </div>
          {/* <div className="grid col-12">
        <div className="field col-6">
          <label>Show in advbooking</label>
          <div>
            <Checkbox
              checked={details.is_show_in_advbooking}
              onChange={(e) => onChange("is_show_in_advbooking", e.checked)}
            />
          </div>
        </div>
      </div> */}
          <div className="field col-12">
            <label htmlFor="key">Description</label>
            <InputText
              id="key"
              value={details.description}
              onChange={(e) => onChange("description", e.target.value)}
              disabled={onlyView}
            />
          </div>
          <div className="field col-6">
            <label>Icon</label>
            <div className="mt-2">
              <VUpload
                urlFile={details.icon}
                setUrlFile={(file) => onChange("icon", file)}
              />
            </div>
          </div>
          <div className="field col-6">
            <label>Thumbnail</label>
            <div className="mt-2">
              <VUpload
                urlFile={details.thumbnail}
                setUrlFile={(file) => onChange("thumbnail", file)}
              />
            </div>
          </div>
          <div className="field col-6">
            <label>Background color</label>
            <div className="mt-2">
              <input
                type="color"
                id="background_color"
                value={details.background_color}
                onChange={(e) => onChange("background_color", e.target.value)}
              />
            </div>
          </div>

          {[
            CATEGORY_EXTRA_FORM_GROUP.PRODUCT_CATEGORY_NORMAL,
            CATEGORY_EXTRA_FORM_GROUP.PRODUCT_CATEGORY_MARTYR,
          ].includes(group) && (
            <div className="field col-12">
              <Panel
                header="Product"
                toggleable
                className="my-2"
                collapsed={true}
              >
                <div className="grid">
                  <div className="flex field col-12">
                    <div className="field col-6">
                      <label htmlFor="attribute">Group Attribute</label>
                      <Dropdown
                        value={details.attribute_id}
                        options={attributeSets?.map((k) => ({
                          label: k.title,
                          value: k._id,
                        }))}
                        showClear
                        optionLabel="label"
                        optionValue="value"
                        filter
                        onChange={(e) => onChange("attribute_id", e.value)}
                        disabled={onlyView}
                      />
                    </div>
                    <div className="field col-6">
                      <label htmlFor="felds">Fields</label>
                      <TreeSelect
                        selectionMode="checkbox"
                        value={selectedNodeFields}
                        filter
                        options={treeFields}
                        onChange={(e: any) => setSelectedNodeFields(e?.value)}
                        className="w-full"
                        placeholder="Select Fields"
                        disabled={onlyView}
                      ></TreeSelect>
                      {/* <MultiSelect
                value={additionalData.fields}
                options={fields}
                filter
                showClear
                optionLabel="label"
                optionValue="value"
                placeholder="Select fields"
                onChange={(e) => {
                  onChangeAddData("fields", e.target.value);
                }}
              /> */}
                    </div>
                  </div>
                  <div className="flex field col-6">
                    <div className="field col-12">
                      <label htmlFor="status">Attributes</label>
                      <MultiSelect
                        value={
                          notChangedAttribute
                            ? attributesSelected
                            : additionalData.attributes
                        }
                        options={attributesChild?.map((item) => ({
                          label: item.label_name,
                          value: item.id,
                        }))}
                        filter
                        showClear
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Select attributes"
                        onChange={(e) => {
                          onChangeAddData("attributes", e.target.value);
                        }}
                        disabled={onlyView}
                      />
                    </div>
                  </div>
                  <div className="field col-6">
                    <label htmlFor="description">Product Type</label>
                    <Dropdown
                      value={additionalData.product_type}
                      options={types.map((k) => ({
                        label: k.name,
                        value: k.key,
                      }))}
                      optionLabel="label"
                      optionValue="value"
                      onChange={(e) => onChangeAddData("product_type", e.value)}
                      showClear
                      disabled={onlyView}
                    />
                  </div>
                  <div className="field col-6">
                    <label htmlFor="description">Product View Type</label>
                    <Dropdown
                      value={additionalData.product_view_type}
                      options={[
                        {
                          label: "Normal",
                          value: "normal",
                        },
                        {
                          label: "Thân nhân",
                          value: "relative",
                        },
                      ]}
                      optionLabel="label"
                      optionValue="value"
                      onChange={(e) =>
                        onChangeAddData("product_view_type", e.value)
                      }
                      disabled={onlyView}
                      showClear
                    />
                  </div>
                  <div className="field col-12 md:col-3">
                    <label htmlFor="is_enable_claim">Is my shop</label>
                    <br />
                    <InputSwitch
                      checked={additionalData?.is_my_shop}
                      onChange={(e) => onChangeAddData("is_my_shop", e.value)}
                      disabled={onlyView}
                    />
                  </div>
                  <div className="field col-12 md:col-3">
                    <label htmlFor="is_enable_claim">Show map</label>
                    <br />
                    <InputSwitch
                      checked={additionalData?.is_show_map}
                      onChange={(e) => onChangeAddData("is_show_map", e.value)}
                      disabled={onlyView}
                    />
                  </div>
                  <div className="field col-12 md:col-3">
                    <label htmlFor="is_enable_claim">Is Free</label>
                    <br />
                    <InputSwitch
                      checked={additionalData?.is_free}
                      onChange={(e) => onChangeAddData("is_free", e.value)}
                      disabled={onlyView}
                    />
                  </div>
                  {/* <div className="field col-12 md:col-3">
                <label htmlFor="is_show_create_post">Show create post</label>
                <br />
                <InputSwitch
                  checked={additionalData?.is_show_create_post}
                  onChange={(e) =>
                    onChangeAddData("is_show_create_post", e.value)
                  }
                />
              </div> */}

                  <div className="field col-6">
                    <label>Icon Maps</label>
                    <div className="mt-2">
                      <VUpload
                        urlFile={additionalData?.icon_maps}
                        setUrlFile={(file) =>
                          onChangeAddData("icon_maps", file)
                        }
                      />
                    </div>
                  </div>
                  <div className="field col-6">
                    <label>Icon Donate</label>
                    <div className="mt-2">
                      <VUpload
                        urlFile={additionalData?.icon_donate}
                        setUrlFile={(file) =>
                          onChangeAddData("icon_donate", file)
                        }
                      />
                    </div>
                  </div>

                  <>
                    <div className="col-12">
                      <h4>Video</h4>
                      <VUpload
                        fileType="video"
                        urlFile={details.video}
                        setUrlFile={(url) => onChange("video", url)}
                      />
                    </div>
                    <div className="col-12">
                      <h4>Images</h4>
                    </div>
                    {details?.images?.map((item, index) => {
                      return (
                        <div className="col-12" key={item.id}>
                          <div className="col-10">
                            <VUpload
                              urlFile={item?.src}
                              setUrlFile={(e) => handleImageChange(e, item.id)}
                            />
                          </div>

                          <div className="col-12 lg:col-10">
                            <span className="p-float-label">
                              <InputText
                                id="name"
                                className="w-full"
                                value={item?.link}
                                onChange={(e) =>
                                  changeLinkItemImages(e, item.id)
                                }
                                disabled={onlyView}
                              />
                              <label htmlFor="link">Link</label>
                            </span>
                          </div>

                          <div className="flex field col-12 md:col-2 md:justify-content-evenly">
                            <Button
                              icon="pi pi-times"
                              className="p-button-rounded p-button-danger"
                              aria-label="Cancel"
                              onClick={(_) => handleSubIndex(item?.id)}
                            />
                            {index === 0 ? (
                              <Button
                                icon="bx bx-chevron-down"
                                className="p-button-rounded p-button-warning"
                                aria-label="Cancel"
                                onClick={(_) => moveDown(index)}
                              />
                            ) : index === details.images.length - 1 ? (
                              <Button
                                icon="bx bx-chevron-up"
                                className="p-button-rounded p-button-primary"
                                aria-label="Cancel"
                                onClick={(_) => moveUp(index)}
                              />
                            ) : (
                              <>
                                <Button
                                  icon="bx bx-chevron-up"
                                  className="p-button-rounded p-button-primary"
                                  aria-label="Cancel"
                                  onClick={(_) => moveUp(index)}
                                />
                                <Button
                                  icon="bx bx-chevron-down"
                                  className="p-button-rounded p-button-warning"
                                  aria-label="Cancel"
                                  onClick={(_) => moveDown(index)}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <Button
                      onClick={handleAdd}
                      icon="bx bxs-add-to-queue"
                      className="p-button-rounded p-button-success mb-3"
                      aria-label="Search"
                    />
                  </>
                </div>
              </Panel>
            </div>
          )}

          <div className="field col-12">
            <label htmlFor="status">Filter</label>
            <Dropdown
              filter
              showClear
              value={details.category_filter_id}
              options={categoryFilters.map((item) => ({
                label: item.name,
                value: item._id,
              }))}
              optionLabel="label"
              optionValue="value"
              placeholder="Select filter"
              onChange={(e) =>
                onInputCheckChange(e.value, "category_filter_id")
              }
              disabled={onlyView}
            />
          </div>
          {/* </div> */}
          <div className="field col-12">
            <Panel header="Filter" toggleable className="my-2" collapsed={true}>
              <div className="grid">
                <div className="field col-12">
                  <CategoryFilterDetails
                    name_key="category_filter_id"
                    ref={refDetailCategory}
                    onChangeOptions={onInputCheckChange}
                  />
                </div>
              </div>
            </Panel>
          </div>

          {group === CATEGORY_EXTRA_FORM_GROUP.PROFILE_DIARYBOOK_NOT_DELETE && (
            <>
              {/* <div className="grid"> */}
              <div className="field col-12">
                <label htmlFor="status">Filter Posting Permission</label>
                <Dropdown
                  filter
                  showClear
                  value={details.permission_category_filter_id}
                  options={categoryFilters.map((item) => ({
                    label: item.name,
                    value: item._id,
                  }))}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select filter"
                  onChange={(e) =>
                    onInputCheckChange(e.value, "permission_category_filter_id")
                  }
                />
              </div>
              {/* </div> */}
              <div className="field col-12">
                <Panel
                  header="Filter"
                  toggleable
                  className="my-2"
                  collapsed={true}
                >
                  <div className="grid">
                    <div className="field col-12">
                      <CategoryFilterDetails
                        name_key="permission_category_filter_id"
                        ref={refDetailPermissionCategory}
                        onChangeOptions={onInputCheckChange}
                      />
                    </div>
                  </div>
                </Panel>
              </div>
              <div className="field col-12 mb-0">
                <label htmlFor="permission">
                  Specify users to post articles
                </label>
              </div>
              {details?.permission_user?.map((item, index) => (
                <div key={index} className="field col-12 md:col-6 flex">
                  <InputText
                    id="key"
                    value={item}
                    placeholder="VDB refer code"
                    onChange={(e) => onChangePermission(index, e.target.value)}
                  />
                  <div className="ml-1">
                    <Button
                      onClick={() => handleRemovePermissionUser(index)}
                      icon="bx bxs-minus-circle"
                      className="p-button-rounded p-button-danger"
                      aria-label="Search"
                    />
                  </div>
                </div>
              ))}
              <div className="field col-12">
                <Button
                  onClick={handleAddPermissionUser}
                  icon="bx bxs-add-to-queue"
                  className="p-button-rounded p-button-success mb-3"
                  aria-label="Search"
                />
              </div>
            </>
          )}
        </div>
      </TabPanel>
      <TabPanel header="Default Info">
        {group === CATEGORY_EXTRA_FORM_GROUP.ACCOUNT_TYPE && (
          <div className="field col-12">
            <div className="grid card my-4">
              <div className="field col-6 md:col-3">
                <span className="p-float-label">
                  <InputText
                    id="key"
                    className="w-full"
                    value={details.default_info?.key}
                    onChange={(e) =>
                      handleChangeDefaultInfo("key", e.target.value)
                    }
                  />
                  <label htmlFor="name">Key</label>
                </span>
              </div>

              <div className="field col-6 md:col-3">
                <span className="p-float-label">
                  <InputText
                    id="name"
                    className="w-full"
                    value={details.default_info?.name}
                    onChange={(e) =>
                      handleChangeDefaultInfo("name", e.target.value)
                    }
                  />
                  <label htmlFor="name">Name</label>
                </span>
              </div>

              <div className="field col-6 md:col-3">
                <span className="p-float-label">
                  <InputText
                    id="position"
                    className="w-full"
                    value={details.default_info?.position}
                    onChange={(e) =>
                      handleChangeDefaultInfo("position", e.target.value)
                    }
                  />
                  <label htmlFor="name">Position</label>
                </span>
              </div>

              <div className="field col-6 md:col-3">
                <span className="p-float-label">
                  <div className="flex align-items-center row">
                    <div className="w-100">
                      <InputText
                        id="text_color"
                        className="w-full"
                        value={details.default_info?.text_color}
                        onChange={(e) =>
                          handleChangeDefaultInfo("text_color", e.target.value)
                        }
                      />
                    </div>
                    <div className="ml-2">
                      <ColorPicker
                        value={details.default_info?.text_color}
                        onChange={(e) =>
                          handleChangeDefaultInfo("text_color", e.value)
                        }
                      />
                    </div>
                  </div>
                </span>
              </div>

              {/* <div className="field col-12 md:col-6">
                <div className="flex align-items-center">
                  <label className="mr-2" htmlFor="name">
                    Show register
                  </label>
                  <InputSwitch
                    checked={details.default_info?.is_show}
                    onChange={(e) =>
                      handleChangeDefaultInfo("is_show", e.value)
                    }
                  />
                </div>
              </div> */}
              <div className="grid col-12">
                <div className="field col-6 md:col-4 text-center">
                  <label htmlFor="name">Logo Kyc</label>
                  <VUpload
                    urlFile={details.default_info?.logo_verify}
                    setUrlFile={(file) =>
                      handleChangeDefaultInfo("logo_verify", file)
                    }
                  />
                </div>
                <div className="field col-6 md:col-4 text-center">
                  <label htmlFor="name">Logo verify</label>
                  <VUpload
                    urlFile={details.default_info?.logo_verify_lv_2}
                    setUrlFile={(file) =>
                      handleChangeDefaultInfo("logo_verify_lv_2", file)
                    }
                  />
                </div>
                <div className="field col-6 md:col-4 text-center">
                  <label htmlFor="name">Logo</label>
                  <VUpload
                    urlFile={details.default_info?.logo}
                    setUrlFile={(file) => handleChangeDefaultInfo("logo", file)}
                  />
                </div>
                <div className="field col-6 md:col-4 text-center">
                  <label htmlFor="name">Avatar</label>
                  <VUpload
                    urlFile={details.default_info?.avatar}
                    setUrlFile={(file) =>
                      handleChangeDefaultInfo("avatar", file)
                    }
                  />
                </div>
                <div className="field col-6 md:col-4 text-center">
                  <label htmlFor="name">Cover</label>
                  <VUpload
                    urlFile={details.default_info?.cover}
                    setUrlFile={(file) =>
                      handleChangeDefaultInfo("cover", file)
                    }
                  />
                </div>
                <div className="field col-6 md:col-4 text-center">
                  <label htmlFor="icon_maps">Icon maps</label>
                  <VUpload
                    urlFile={details.default_info?.icon_maps}
                    setUrlFile={(file) =>
                      handleChangeDefaultInfo("icon_maps", file)
                    }
                  />
                </div>

                <div className="field col-6 md:col-4 text-center">
                  <label htmlFor="icon_maps">Background</label>
                  <VUpload
                    urlFile={details.default_info?.background}
                    setUrlFile={(file) =>
                      handleChangeDefaultInfo("background", file)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </TabPanel>
    </TabView>
  );
};

export default forwardRef(Details);
