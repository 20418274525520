import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState, useImperativeHandle, forwardRef } from "react";
import { nanoid } from "nanoid";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { DATA_TYPES, EFIELD, FIELDS } from "../types";
import AttributeSetService from "services/attribute-set";
import { showToast } from "utils/common";

const ProductDetail = (props: any, ref: any) => {
  const { reload, data, setLoading, toast, onCancel } = props;
  const [details, setDetails] = useState(data || {});

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const submit = async () => {
    try {
      setLoading(true);
      const { show_drawer, ...remain } = details;
      if (data) {
        await AttributeSetService.update({
          params: {
            id: data._id,
          },
          body: remain,
        });
      } else
        await AttributeSetService.create({
          body: remain,
        });
      setLoading(false);
      showToast(toast, "success", "Saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const changeSubOptionBottomHandler = (
    attribute: any,
    type: string,
    id: string,
    name: string,
    value: any,
  ) => {
    const target: any[] = attribute[type];
    target.forEach((option: any) => {
      if (option?.id === id) {
        option[name] = value;
      }
    });
    handleChangeAttributeDetail(attribute.id, type, target);
  };

  const removeAttribute = (id: string) => {
    setDetails((state) => ({
      ...state,
      attributes: state.attributes.filter((item) => item.id !== id),
    }));
  };

  const subOptionBottomRender = (attribute: any) => {
    switch (attribute?.field_type) {
      case EFIELD.MULTI_CHOISE: {
        return (
          <div>
            <p>Options:</p>
            <div className="flex gap-3">
              <Button
                icon="pi pi-plus"
                onClick={() => {
                  handleChangeAttributeDetail(
                    attribute.id,
                    "multi_choise_options",
                    [
                      ...attribute?.multi_choise_options,
                      {
                        id: nanoid(8),
                        name: "",
                        is_correct_answer: false,
                      },
                    ],
                  );
                }}
              />
            </div>
            <div className="h-96 overflow-auto">
              {attribute.multi_choise_options?.map((item: any) => (
                <div key={item.id} className="my-2">
                  <div className="relative">
                    <div className="fleld">
                      <label>Tên</label>
                      <InputText
                        value={item?.name}
                        onChange={(e) =>
                          changeSubOptionBottomHandler(
                            attribute,
                            "multi_choise_options",
                            item.id,
                            "name",
                            e.target.value,
                          )
                        }
                      />
                    </div>
                    <Button
                      icon="pi pi-trash"
                      onClick={() =>
                        handleChangeAttributeDetail(
                          attribute.id,
                          "multi_choise_options",
                          attribute.multi_choise_options?.filter(
                            (i: any) => i.id !== item.id,
                          ),
                        )
                      }
                    />
                  </div>
                  <div className="flex align-items-center mt-2">
                    <label>Đáp án chính xác</label>
                    <InputSwitch
                      checked={item?.is_correct_answer}
                      onChange={(e) =>
                        changeSubOptionBottomHandler(
                          attribute,
                          "multi_choise_options",
                          item.id,
                          "is_correct_answer",
                          e.value,
                        )
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      }
      case EFIELD.CHECKBOXES: {
        return (
          <div>
            <p>Options:</p>
            <div className="flex gap-3">
              <Button
                icon="pi pi-plus"
                onClick={() => {
                  handleChangeAttributeDetail(
                    attribute.id,
                    "checkbox_options",
                    [
                      ...attribute?.checkbox_options,
                      {
                        id: nanoid(8),
                        name: "",
                        is_correct_answer: false,
                      },
                    ],
                  );
                }}
              />
            </div>
            <div className="max-h-96 overflow-auto">
              {attribute.checkbox_options?.map((item: any) => (
                <div key={item.id} className="my-2">
                  <div className="relative">
                    <div className="field">
                      <label>Tên</label>
                      <InputText
                        value={item?.name}
                        onChange={(e) =>
                          changeSubOptionBottomHandler(
                            attribute,
                            "checkbox_options",
                            item.id,
                            "name",
                            e.target.value,
                          )
                        }
                      />
                    </div>
                    <Button
                      icon="pi pi-trash"
                      className="absolute top-8 right-1"
                      onClick={() =>
                        handleChangeAttributeDetail(
                          attribute.id,
                          "checkbox_options",
                          attribute.checkbox_options?.filter(
                            (i: any) => i.id !== item.id,
                          ),
                        )
                      }
                    />
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <label>Đáp án chính xác</label>{" "}
                    <InputSwitch
                      checked={item?.is_correct_answer}
                      onChange={(e) =>
                        changeSubOptionBottomHandler(
                          attribute,
                          "checkbox_options",
                          item.id,
                          "is_correct_answer",
                          e.value,
                        )
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      }
      case EFIELD.RADIO: {
        return (
          <div>
            <p>Options:</p>
            <div className="flex gap-3">
              <Button
                icon="pi pi-plus"
                onClick={() => {
                  handleChangeAttributeDetail(attribute.id, "radio_options", [
                    ...(attribute?.radio_options || []),
                    {
                      id: nanoid(8),
                      name: "",
                      is_correct_answer: false,
                    },
                  ]);
                }}
              />
            </div>
            <div className="max-h-96 overflow-auto">
              {attribute.radio_options?.map((item: any) => (
                <div key={item.id} className="my-2">
                  <div className="relative">
                    <div className="field">
                      <label>Tên</label>
                      <InputText
                        value={item?.name}
                        onChange={(e) =>
                          changeSubOptionBottomHandler(
                            attribute,
                            "radio_options",
                            item.id,
                            "name",
                            e.target.value,
                          )
                        }
                      />
                    </div>
                    <Button
                      icon="pi pi-trash"
                      onClick={() =>
                        handleChangeAttributeDetail(
                          attribute.id,
                          "radio_options",
                          attribute.radio_options?.filter(
                            (i: any) => i.id !== item.id,
                          ),
                        )
                      }
                    />
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <label>Đáp án chính xác</label>
                    <InputSwitch
                      checked={item?.is_correct_answer}
                      onChange={(e) =>
                        changeSubOptionBottomHandler(
                          attribute,
                          "radio_options",
                          item.id,
                          "is_correct_answer",
                          e.value,
                        )
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      }
      case EFIELD.DROPDOWN: {
        return (
          <div>
            <p>Options:</p>
            <div className="flex gap-3">
              <Button
                icon="pi pi-plus"
                className="mr-2"
                onClick={() => {
                  handleChangeAttributeDetail(
                    attribute.id,
                    "dropdown_options",
                    [
                      ...attribute?.dropdown_options,
                      {
                        id: nanoid(8),
                        name: "",
                        trademark: false,
                        trademark_list: [],
                      },
                    ],
                  );
                }}
              />
              {/* <Button */}
              {/*   className="w-auto" */}
              {/*   onClick={ */}
              {/*     () => {} */}
              {/*     setImportModal((state) => ({ ...state, show: true })) */}
              {/*   } */}
              {/* > */}
              {/*   Import */}
              {/* </Button> */}
            </div>
            <div className="max-h-96 overflow-auto">
              {attribute.dropdown_options?.map((item: any) => (
                <div key={item.id} className="my-2">
                  <div>
                    <div className="field flex-1">
                      <label>Tên</label>
                      <InputText
                        value={item?.name}
                        onChange={(e) =>
                          changeSubOptionBottomHandler(
                            attribute,
                            "dropdown_options",
                            item.id,
                            "name",
                            e.target.value,
                          )
                        }
                      />
                    </div>
                    <Button
                      icon="pi pi-trash"
                      onClick={() =>
                        handleChangeAttributeDetail(
                          attribute.id,
                          "dropdown_options",
                          attribute.dropdown_options?.filter(
                            (i: any) => i.id !== item.id,
                          ),
                        )
                      }
                    />
                  </div>
                  <div className="field flex items-center gap-2 my-2">
                    <label>Nhãn hiệu</label>{" "}
                    <InputSwitch
                      checked={item?.trademark}
                      onChange={(e) =>
                        changeSubOptionBottomHandler(
                          attribute,
                          "dropdown_options",
                          item.id,
                          "trademark",
                          e.value,
                        )
                      }
                    />
                  </div>
                  {item?.trademark && (
                    <>
                      <label>Thêm / xoá nhãn hiệu:</label>
                      <div className="mb-3">
                        <Button
                          onClick={() => {
                            const newTrademark = {
                              id: nanoid(8),
                              name: "",
                              link: "",
                            };
                            const trademarkList = [
                              ...item?.trademark_list,
                              newTrademark,
                            ];
                            changeSubOptionBottomHandler(
                              attribute,
                              "dropdown_options",
                              item.id,
                              "trademark_list",
                              trademarkList,
                            );
                          }}
                        >
                          +
                        </Button>
                      </div>
                      <div className="ml-4">
                        {item?.trademark_list.map((trademark: any) => (
                          <div key={trademark.id} className="mb-5">
                            <div className="field">
                              <label>Tên</label>
                              <InputText
                                className="mb-2"
                                value={trademark.name}
                                onChange={(e) => {
                                  const trademarkList = item?.trademark_list;
                                  trademarkList.forEach((t: any) => {
                                    if (t.id === trademark.id) {
                                      t.name = e.target.value;
                                    }
                                  });
                                  changeSubOptionBottomHandler(
                                    attribute,
                                    "dropdown_options",
                                    item.id,
                                    "trademark_list",
                                    trademarkList,
                                  );
                                }}
                              />
                            </div>
                            <div className="field">
                              <label>Liên kết</label>
                              <InputText value={trademark.link} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      }
    }
  };

  const handleAddAttribute = () => {
    const newAttribute = {
      id: nanoid(8),
      quantity: 0,
      required: false,
      label: false,
      volunteer: false,
      label_name: "",
      label_key: "",
      multi_choise_options: [],
      checkbox_options: [],
      dropdown_options: [],
      field_type: "input",
    };
    setDetails((state) => ({
      ...state,
      attributes: [...(state?.attributes || []), newAttribute],
    }));
  };

  const handleChangeAttributeDetail = (id: string, key: string, value: any) => {
    setDetails((state) => ({
      ...state,
      attributes: state.attributes?.map((i) => {
        if (id === i.id) {
          i[key] = value;
        }
        return i;
      }),
    }));
  };

  const handleChangeDetails = (key: string, value: any) => {
    setDetails((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const optionToggleRender = (attribute: any) => {
    switch (attribute?.field_type) {
      case "input": {
        return (
          <div className="flex align-items-center ml-3">
            <label>Discount %:</label>
            <InputSwitch
              checked={attribute?.enable_discount}
              onChange={(e) =>
                handleChangeAttributeDetail(
                  attribute.id,
                  "enable_discount",
                  e.value,
                )
              }
              className="ml-2"
            />
          </div>
        );
      }
      case "dropdown": {
        return (
          <div className="flex align-items-center ml-3">
            Set Bộ lọc
            <InputSwitch
              checked={attribute?.filter}
              onChange={(e) =>
                handleChangeAttributeDetail(attribute.id, "filter", e.value)
              }
              className="ml-2"
            />
          </div>
        );
      }
    }
  };

  const keyOptions = [
    {
      name: "martyr_name",
    },
    {
      name: "position_row",
    },
    {
      name: "position_col",
    },
  ];

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="title">Title</label>
        <InputText
          id="title"
          required
          autoFocus
          value={details?.title}
          onChange={(e) => handleChangeDetails("title", e.target.value)}
        />
      </div>
      <div>
        <Button
          label="Smart Fields"
          icon="pi pi-plus"
          onClick={handleAddAttribute}
        />
      </div>
      <div className="grid col-12 mt-5">
        {details.attributes?.map((i) => (
          <div className="field col-12">
            <div className="flex">
              <InputNumber
                className="w-1"
                value={i?.quantity}
                onChange={(e) =>
                  handleChangeAttributeDetail(i.id, "quantity", e.value)
                }
              />
              {(i?.has_label_key || i.label_key?.length > 0) && (
                <Dropdown
                  options={keyOptions}
                  className="w-5 ml-2"
                  placeholder="Choose key"
                  optionLabel="name"
                  optionValue="name"
                  onChange={(e) =>
                    handleChangeAttributeDetail(i.id, "label_key", e.value)
                  }
                  value={i?.label_key}
                />
              )}
            </div>
            <InputText
              value={i?.label_name}
              className="field mt-2 w-7"
              onChange={(e) =>
                handleChangeAttributeDetail(i.id, "label_name", e.target.value)
              }
            />
            <div className="field border p-3 flex justify-content-between">
              <div className="flex align-items-center">
                <div className="flex align-items-center">
                  <label htmlFor="required">Required</label>
                  <InputSwitch
                    className="ml-2"
                    checked={i?.required}
                    onChange={(e) =>
                      handleChangeAttributeDetail(i.id, "required", e.value)
                    }
                  />
                </div>
                <div className="flex align-items-center ml-3">
                  <label htmlFor="key_field">Key Field</label>
                  <InputSwitch
                    className="ml-2"
                    checked={i?.has_label_key}
                    onChange={(e) =>
                      handleChangeAttributeDetail(
                        i.id,
                        "has_label_key",
                        e.value,
                      )
                    }
                  />
                </div>
                {i?.field_type === EFIELD.DROPDOWN && (
                  <div className="flex align-items-center ml-3">
                    <label htmlFor="system_attr">System</label>
                    <InputSwitch
                      className="ml-2"
                      checked={i?.system}
                      onChange={(e) => {
                        handleChangeAttributeDetail(i.id, "system", e.value);
                        if (e.value) {
                          handleChangeAttributeDetail(
                            i.id,
                            "permutation",
                            false,
                          );
                        }
                      }}
                    />
                  </div>
                )}
                {i?.system &&
                  [EFIELD.DROPDOWN, EFIELD.DATE_TIME].includes(
                    i?.field_type,
                  ) && (
                    <div className="flex align-items-center ml-3">
                      <label htmlFor="permute">Permute</label>
                      <InputSwitch
                        className="ml-2"
                        checked={i?.permutation}
                        onChange={(e) =>
                          handleChangeAttributeDetail(
                            i.id,
                            "permutation",
                            e.value,
                          )
                        }
                      />
                    </div>
                  )}
                {optionToggleRender(i)}
              </div>
              <div>
                <Button
                  icon="pi pi-arrow-right"
                  onClick={() =>
                    handleChangeAttributeDetail(
                      i.id,
                      "show_drawer",
                      !i?.show_drawer,
                    )
                  }
                />
                <Button
                  icon="pi pi-trash"
                  className="ml-2"
                  onClick={() => removeAttribute(i.id)}
                />
              </div>
            </div>
            {i?.show_drawer && (
              <div className="border p-3">
                <div className="field">
                  <label htmlFor="placeholder">Placeholder</label>
                  <InputText
                    id="placeholder"
                    value={i?.placeholder}
                    onChange={(e) =>
                      handleChangeAttributeDetail(
                        i.id,
                        "placeholder",
                        e.target.value,
                      )
                    }
                  />
                </div>
                <div className="field">
                  <label htmlFor="field_type">Loại field</label>
                  <Dropdown
                    options={FIELDS}
                    value={i?.field_type || "input"}
                    onChange={(e) =>
                      handleChangeAttributeDetail(i.id, "field_type", e.value)
                    }
                    placeholder="Chọn loại trường"
                    optionLabel="name"
                    optionValue="value"
                  />
                </div>
                <div>{subOptionBottomRender(i)}</div>
                {i?.label_key && (
                  <div className="field">
                    <label htmlFor="data_types">Validate / Data type</label>
                    <Dropdown
                      options={DATA_TYPES}
                      value={i?.data_type}
                      onChange={(e) =>
                        handleChangeAttributeDetail(i.id, "data_type", e.value)
                      }
                      placeholder={"Chọn kiểu dữ liệu"}
                      optionLabel="name"
                      optionValue="value"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default forwardRef(ProductDetail);
